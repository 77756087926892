<script>
import {
    HorizontalBar,
    mixins
} from 'vue-chartjs'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

export default {
    extends: HorizontalBar,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.addPlugin(ChartJsPluginDataLabels);
        const self = this
        const option = Object.assign({}, this.options);
        option.scales = {
            xAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                        return self.commarize(value, 1000);
                    },
                    beginAtZero: true
                }
            }]
        }
        option.tooltips = {
          enabled: true,
          callbacks: {
            label: ((tooltipItems, chartData) => {
              return self.commarized(tooltipItems.xLabel);
            })
          }
        }
        option.plugins = {
            datalabels: {
                labels: {
                    value: {
                        color: 'white'
                    }
                },
                formatter: function(value, context) {
                    return self.commarized(value);
                }
            }
        }        
        // option.onClick = this.handle
        this.renderChart(this.chartData, option)
    },
    methods: {
        commarize(value, min) {
            min = min || 1e3;
            // Alter numbers larger than 1k
            if (value >= min) {
                var units = ["k", "M", "B", "T"];

                var order = Math.floor(Math.log(value) / Math.log(1000));

                var unitname = units[(order - 1)];
                var num = value / 1000 ** order;

                // output number remainder + unitname
                return num + unitname
            }

            // return formatted original number
            return value.toLocaleString()
        },
        commarized(value) {
            var numeral = require("numeral");
            return numeral(value).format("0,0[.]00");
        },
        handle (point, event) {
            //console.log(this.chartData)
            const item = event[0]
            this.$emit('on-receive', {
                value: this.chartData.labels[item._index]
            })
        }
    }
}
</script>
