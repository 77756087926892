<script>
import {
    Pie,
    mixins
} from 'vue-chartjs'
import 'chartjs-plugin-labels';

export default {
    extends: Pie,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        const option = Object.assign({}, this.options);
        option.plugins = {
            labels: {
                render: 'percentage',
                fontColor: (data) => {
                    var rgb = this.convertHex(data.dataset.backgroundColor[data.index]);
                    var threshold = 140;
                    var luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
                    return luminance > threshold ? 'black' : 'white';
                },
                precision: 1,
                position: 'border',
                overlap: false
            },
        }
        option.scales = {
            xAxes: [{
                display: false
            }]
        }
        option.tooltips = {
            enabled: true,
            callbacks: {
                label: ((tooltipItem, data) => {
                    let dataset = data.datasets[tooltipItem.datasetIndex]
                    let currentValue = dataset.data[tooltipItem.index]
                    var numeral = require("numeral");

                    return numeral(currentValue).format("0,0[.]00");
                })
            }
        }
        this.renderChart(this.chartData, option)
    },
    methods: {
        convertHex: function (color) {
            color = color.replace('#', '')
            let r = parseInt(color.substring(0, 2), 16)
            let g = parseInt(color.substring(2, 4), 16)
            let b = parseInt(color.substring(4, 6), 16)
            let result = 'rgba(' + r + ',' + g + ',' + b + ',' + this.opacity / 100 + ')'
            return result
        }
    }
}
</script>
