<script>
import {
    Line,
    mixins
} from 'vue-chartjs'
const {
    reactiveProp
} = mixins

export default {
    extends: Line,
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.draw(this.chartData)
    },
    methods: {
        draw(data) {
          const self = this
          const option = Object.assign({}, this.options);
          option.scales = {
              yAxes: [{
                  ticks: {
                      // Include a dollar sign in the ticks
                      callback: function (value, index, values) {
                          return self.commarize(value, 1000);
                      },
                      beginAtZero: true
                  }
              }]
          },
            option.tooltips = {
                callbacks: {
                    label: function(tooltipItem, data) {
                        // console.log("tooltipItem: " +tooltipItem.yLabel);
                        if(tooltipItem.yLabel % 1 ==0) {
                            return tooltipItem.yLabel.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        }
                    }
                }
            }
          this.renderChart(this.chartData, option)
        },
        commarize(value, min) {
            min = min || 1e3;
            // Alter numbers larger than 1k
            if (value >= min) {
                var units = ["k", "M", "B", "T"];

                var order = Math.floor(Math.log(value) / Math.log(1000));

                var unitname = units[(order - 1)];
                var num = value / 1000 ** order;

                // output number remainder + unitname
                return num + unitname
            }

            // return formatted original number
            return value.toLocaleString()
        },
        addCommas(nStr) {
            nStr += '';
            x = nStr.split('.');
            x1 = x[0];
            x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        }
    }
}
</script>
